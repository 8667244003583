
import './App.css';

function App() {
 return (
  <div>
   <div className='header'>

    <span className='xls'>xls</span>
    <span className='n'>2</span>
    <span className='sms'>SMS</span>

    <span></span>


   </div>
   <div className='bdy'>

    <p>Under construction….Site coming soon!</p>



   </div>
  </div>
 );
}

export default App;



